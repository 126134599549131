export const beats = {
  "2/4": [2, 4],
  "3/4": [3, 4],
  "4/4": [4, 4],
  "5/4": [5, 4],
  "6/4": [6, 4],
  "7/4": [7, 4]
}

export default beats
