import React, { PureComponent } from "react"
import * as utils               from "../../../utils"

export default class Terms extends PureComponent {
  componentDidMount = () => utils.setMeta("利用規約", "", this.props.history)
  render() {
    return (
      <div className="content terms">
        <h1>利用規約・プライバシーポリシー</h1>
        <p>
          この利用規約 (以下、「本規約」といいます) は、
          コモレビノーツ (以下、「当方」といいます) が
          このウェブサイト上で提供する rechord (以下、「本サービス」といいます) の利用条件を定めるものです。
          登録ユーザの皆さま (以下、「ユーザ」といいます) には、本規約に従って、本サービスをご利用いただきます。
        </p>

        <ol className="terms-list">
          <li>
            <h2>適用</h2>
            <p>
              本規約は、ユーザと当方との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            </p>
          </li>

          <li>
            <h2>アカウントおよびパスワード</h2>
            <p>
              本サービスは、特定の機能を利用する際に、各種外部サイトのアカウントとの連携を必要とすることがあります。
              その場合、ユーザが入力したアカウント情報をサーバに保管しますが、保管されるアカウント情報にはパスワードは一切含まれません。
              また、ユーザは、自己のアカウント情報の不正利用の防止に努めるとともに、その管理について一切の責任を負うものとします。
              アカウント情報が第三者に利用されたことによって生じた損害等につきましては、当方はいかなる責任も負いません。
            </p>
          </li>

          <li>
            <h2>第三者への提供</h2>
            <p>
              本サービスは、サーバに保管されたアカウント情報、ユーザのアクセスログなどを、第三者に提供することはありません。
            </p>
          </li>

          <li>
            <h2>ユーザの責任</h2>
            <p>
              ユーザは、自己の責任において本サービスを利用するものとし、
              本サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。
              本サービスを利用してユーザが投稿した投稿作品の情報に関する責任は、全てユーザに帰属します。
              当方はユーザが本サービスを利用して投稿した投稿作品の情報の内容について、一切責任を負いません。
              ユーザが他人の名誉を毀損した場合、プライバシー権を侵害した場合、
              許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか他人の権利を侵害した場合には、
              当該ユーザは自身の責任と費用においてこれを解決しなければならず、当方は一切の責任を負いません。
            </p>
          </li>

          <li>
            <h2>投稿作品の情報に関する権利</h2>
            <p>
              本サービスを利用して投稿作品の情報を投稿するユーザは、当方に対し、
              当該投稿作品の情報が第三者の権利を侵害するものでないこと保証するものとします。
              万一、ユーザが投稿した投稿作品が第三者の権利を侵害するなど第三者との間で何らかの紛争が発生した場合には、
              当該ユーザの費用と責任において問題を解決するとともに、当方に何等の損害を与えないものとします。
            </p>
          </li>

          <li>
            <h2>禁止事項</h2>
            <p>
              ユーザは、本サービスの利用にあたり、以下の行為をしてはなりません。
            </p>
            <ol>
              <li>法令または公序良俗に違反する行為</li>
              <li>犯罪行為に関連する行為</li>
              <li>当方のサーバまたはネットワークの機能を破壊したり、妨害したりする行為</li>
              <li>当方のサービスの運営を妨害するおそれのある行為</li>
              <li>他のユーザに関する個人情報等を収集または蓄積する行為</li>
              <li>他のユーザに成りすます行為</li>
              <li>当方のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
              <li>その他、当方が不適切と判断する行為</li>
            </ol>
          </li>

          <li>
            <h2>本サービスの提供の停止等</h2>
            <p>
              当方は、以下のいずれかの事由があると判断した場合、
              ユーザに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            </p>
            <ol>
              <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
              <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
              <li>コンピュータまたは通信回線等が事故により停止した場合</li>
              <li>その他、当方が本サービスの提供が困難と判断した場合</li>
            </ol>
            <p>
              当方は、本サービスの提供の停止または中断により、
              ユーザまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
            </p>
          </li>

          <li>
            <h2>利用制限および登録抹消</h2>
            <p>
              当方は、以下の場合には、事前の通知なく、ユーザに対して、
              本サービスの全部もしくは一部の利用を制限し、またはユーザとしての登録を抹消することができるものとします。
            </p>
            <ol>
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>登録事項に虚偽の事実があることが判明した場合</li>
              <li>その他、当方が本サービスの利用を適当でないと判断した場合</li>
            </ol>
            <p>
              当方は、本条に基づき当方が行った行為によりユーザに生じた損害について、一切の責任を負いません。
            </p>
          </li>

          <li>
            <h2>免責事項</h2>
            <p>
              当方の債務不履行責任は、当方の故意または重過失によらない場合には免責されるものとします。
              当方は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内においてのみ賠償の責任を負うものとします。
              当方は、本サービスに関して、ユーザと他のユーザまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
            </p>
          </li>

          <li>
            <h2>サービス内容の変更等</h2>
            <p>
              当方は、ユーザに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、
              これによってユーザに生じた損害について一切の責任を負いません。
            </p>
          </li>

          <li>
            <h2>利用規約の変更</h2>
            <p>
              当方は、必要と判断した場合には、ユーザに通知することなくいつでも本規約を変更することができるものとします。
            </p>
          </li>

          <li>
            <h2>通知または連絡</h2>
            <p>
              ユーザと当方との間の通知または連絡は、当方の定める方法によって行うものとします。
            </p>
          </li>

          <li>
            <h2>権利義務の譲渡の禁止</h2>
            <p>
              ユーザは、当方の書面による事前の承諾なく、
              利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
            </p>
          </li>

          <li>
            <h2>準拠法・裁判管轄</h2>
            <p>
              本規約の解釈にあたっては、日本法を準拠法とします。
              本サービスに関して紛争が生じた場合には、当方の本店所在地を管轄する裁判所を専属的合意管轄とします。
            </p>
          </li>
        </ol>

        <h1>プライバシーポリシー</h1>
        <ol className="terms-list">
          <li>
            <h2>Google Analytics</h2>
            <p>本サービスでは、サイトのサービスの分析と改善のために Google アナリティクスを使用しています。</p>
            <p>
              本サイトをご利用中のウェブブラウザは、
              Google に特定の情報（たとえば、アクセスしたページのウェブ アドレスや IP アドレスなど）を自動的に送信します
              また、データ収集のため、Google がお使いのブラウザに cookie を設定したり、既存の cookie を読み取ったりすることがあります。
              ユーザは、本サイトを利用することで、上記方法および目的において、Google が行うこうしたデータ処理につき許可を与えたものとみなします。
            </p>
            <p>
              <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">
                Google のプライバシーポリシーについてはこちらをご覧ください。
              </a>
            </p>
          </li>

          <li>
            <h2>Google AdSense</h2>
            <p>本サービスでは、Google AdSense を使用しています。</p>
            <p>
              本サービスの Google AdSense による広告においては、Google などの第三者配信事業者が cookie を使用して、
              ユーザが本サービスや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信します。
              そして Google が広告 cookie を使用することにより、ユーザが本サービスや他のウェブサイトにアクセスした際の情報に基づいて、
              Google やそのパートナーが適切な広告をユーザに表示することができます。
              Google によるこれらのパーソナライズ広告は、ユーザの判断で停止することができます。
              停止を希望される方は、
              <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
                Google の広告設定
              </a>
              から無効にしてください。
            </p>
            <p>
              <a href="https://www.google.co.jp/policies/technologies/ads/" target="_blank" rel="noopener noreferrer">
                Google の広告に関するプライバシーポリシーについてはこちらをご覧ください。
              </a>
            </p>
          </li>

          <li>
            <h2>プライバシーポリシーの改定について</h2>
            <p>本サービスでは、予告なくしてプライバシーポリシーの全部又は一部を改定することがあります。</p>
          </li>
        </ol>
      </div>
    )
  }
}
